@import '../../../index.scss';
@import '../../../sass/mixins';


.addNoteBook {
  .container {
    .head {
      
    }
    .content {
      form {
        display: flex;
        flex-direction: column;
        gap: 32px;
        .inputBox {
          display: flex;
          gap: 32px;
          @media (max-width: $md-media) {
            flex-direction: column;
            gap: 16px;
          }
          .inputWrapper {
            flex: 0 0 45%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            @media (max-width: $md-media) {
              flex: 0 0 100%;
            }
            label {
              font-size: 14px;
              color: $gray-300;
              padding-inline-start: 4px;
            }
          }
        }
        .addBtnBox {
          // margin-top: 16px;
          margin-bottom: 16px;
          width: 100%;
          .addBtn {
            background-color: $yellow-F0;
            color: $white;
            padding: 8px 16px;
            width: fit-content;
            @include borderRadius(4px);
            border: 1px solid $yellow-F0;
            font-size: 18px;
            min-width: 150px;
            opacity: 1;
            @include transition(0.2s);
            &:hover {
              opacity: 0.7;
            }
            @media (max-width: $xs-media) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}