@import '../../../index.scss';
@import '../../../sass/mixins';



.login {
  background-color: $bg-F4;
  width: 100%;
  min-height: 100vh;
  // height: 100vh;
  @media (max-width: $sm-media) {
    padding-left: 16px;
    padding-right: 16px;
  }
  .wrapper {
    margin-top: 52px;
    margin-bottom: 52px;
    min-width: 460px;
    // min-height: 460px;
    box-shadow: $shadow-A01;
    background-color: $white;
    @include borderRadius(0.45rem);
    @media (max-width: $md-media) {
      min-width: 80%;
    }
    @media (max-width: $sm-media) {
      min-width: 90%;
    }
    @media (max-width: $xs-media) {
      min-width: 100%;
    }
    .header {
      padding: 21px;
      .logo {
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          // width: 100%;
          // height: 100%;
          // object-fit: cover;
        }
      }
      .text {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dadada;
        margin: 10px 0px 20px;
        line-height: 0.1em;
        font-size: 16px;
        padding-top: 20px;

        span {
          padding: 0px 10px;
          background-color: $white;
          line-height: 0.1em;
        }
      }
    }
    .content {
      padding: 21px;
      .form {
        .rememberBox {
          margin-top: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          @media (max-width: $sm-media) {
            flex-direction: column;
            align-items: start;
          }
          .inputBox2 {
            display: flex;
            align-items: center;
            gap: 8px;
            input {
              width: 20px;
              height: 20px;
              border: 1px solid $primary;
              outline: none;
              @include borderRadius(4px);
              color: #00784B;
              font-size: 18px;
              font-weight: 500;
              cursor: pointer;
              &:focus,
              &:hover {
                box-shadow: $shadow-15;
              }
            }
            label {
              color: $secondary;
              cursor: pointer;
            }
          }
        }
        .loginBtn {
          margin-top: 1.5rem;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background-color: $primary;
            color: $white;
            padding: 8px 16px;
            @include borderRadius(.25rem);
            width: 100%;
            font-size: 18px;
            opacity: 1;
            @include transition(0.2s);
            svg {
              font-size: 20px;
            }
            span {

            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .footer {
      padding: 21px;
      border-top: 1px solid #EEE;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      @media (max-width: $sm-media) {
        flex-direction: column;
        align-items: start;
      }
      .signUp {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .link {
      color: $primary;
      @include transition(0.2s);
      &:hover {
        color: $blue-0c;
      }
    }
  }
}