@import '../../../index.scss';
@import '../../../sass/mixins';

.fullWidthSpinner,
.customSpinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .image {
    width: 80px;
    @include animate(rotation 1s infinite linear);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.customSpinner {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $overflow-black5;
  z-index: 2000;
  .image {
    width: 50px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
    -webkit-transform: rotate(365deg);
    -moz-transform: rotate(365deg);
    -ms-transform: rotate(365deg);
    -o-transform: rotate(365deg);
}
}
