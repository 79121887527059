@import "../../../index.scss";
@import "../../../sass/mixins";

.archievePage {
  position: relative;
  .container {
    .addLink {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      .link {
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: $yellow-F0;
        color: $white;
        padding: 8px 16px;
        width: fit-content;
        @include borderRadius(4px);
        border: 1px solid $yellow-F0;
        font-size: 18px;
        min-width: 150px;
        opacity: 1;
        @include transition(0.2s);
        &:hover {
          opacity: 0.7;
        }
        @media (max-width: $xs-media) {
          width: 100%;
        }
        .text {
        }
        .icon {
            font-size: 20px;
        }
      }
    }
    .archieveTable {
    }
  }
}
