@import '../../index.scss';
@import '../../sass/mixins';


.expenses {
    .container {
        .head {

        }
        .tabs {
            display: flex;
            align-items: center;
            gap: 12px;
            @media (max-width: $md-media) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
            .addParent,
            .addChild,
            .allTypes {           
                padding: 6px 16px;
                @include borderRadius(4px);
                border: 1px solid transparent;
                color: $white;
                opacity: 0.5;
                @include transition(0.2s);
                &:hover {
                    opacity: 0.8;
                }
                // &.active {
                //     opacity: 0.4;
                //     color: $white !important;
                // }
                @media (max-width: $md-media) {
                    width: 200px;
                }
            }
            .addParent {
                background-color: $secondary;
                border-color: $secondary;
            }
            .addChild {
                background-color: $blue-0c;
                border-color: $blue-0c;
            }
            .allTypes {
                background-color: $yellow-F4;
                border-color: $yellow-F4;
            }
            .activeLink {
                box-shadow: $shadow-A03;
                opacity: 1;
            }

        }

    }
}