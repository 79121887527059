@import "../../../index.scss";
@import "../../../sass/mixins";

.fullScreenImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-A01;

  .container {
    position: relative;
    width: 60vw;
    height: 90vh;
    background-color: $bg-green;
    @include borderRadius(4px);
    .image {
      width: 100%;
      height: 100%;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .closeIcon {
      font-size: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      fill: $white;
      color: $bg-red;
      cursor: pointer;
      @include transition(0.2s);
      &:hover {
        color: $bg-red;
        fill: $bg-red;
      }
    }
  }
}
