@import '../../../../index.scss';
@import '../../../../sass/mixins';


.notifiMenu {
  width: 24rem;
  min-width: 11rem;
  z-index: 1000;
  position: absolute;
  top: 48px;
  // left: -16px;
  // right: -16px;
  left: -100px;
  right: -100px;
  background-color: $white;
  box-shadow: $shadow-A01;
  @include borderRadius(4px);
  padding-top: 8px;
  padding-bottom: 8px;
  @media (max-width: $sm-media) {
    position: fixed;
    top: 64px;
    left: 16px;
    right: 16px;
    width: calc(90vw - 16px - 16px);
  }
  @media (max-width: $xs-media) {
    position: fixed;
    top: 64px;
    left: 16px;
    right: 16px;
    width: calc(100vw - 16px - 16px);
  }
  // @media (max-width: $sm-media) {
  //   left: -16rem;
  //   right: -16rem;
  // }
  // @media (max-width: $xs-media) {
  //   width: 90vw;
  //   left: -13rem;
  //   right: -13rem;
  // }
  // @media (max-width: $xxs-media) {
  //   width: 90vw;
  //   left: -10rem;
  //   right: -10rem;
  // }
  .head {
    padding: 10px 20px;
    border-bottom: 1px solid #E4E5EC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .title {
      text-transform: uppercase;
      color: #626e82;
      font-size: .875rem;
      font-weight: 400;
      line-height: 1.2;
    }
    .number {
      background-color: #ff4961;
      color: $white;
      padding: 0.35em 0.4em;
      @include borderRadius(4px);
      font-size: 14px;
    }
  }
  .body {
    max-height: 18.2rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .list {
      display: flex;
      flex-direction: column;
      .item {
        padding: 14px;
        display: flex;
        align-items: center;
        gap: 14px;
        &:not(:last-of-type) {
          border-bottom: 1px solid #E4E5EC;
        }
        .image {
          width: 30px;
          height: 30px;
          @include borderRadius(50%);
          border: 1px solid $gray-100;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $yellow-F0;
          svg {
            color: $white;
            font-size: 18px;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .title {
            line-height: 1.2;
            color: #464855;
            font-size: 16px;
          }
          .details {
            color: #6b6f82;
            font-size: 14px;
          }
          .time {
            color: #6b6f82;
            font-size: 12px;
          }
        }
      }
    }
  }
  .footer {
    border-top: 1px solid #E4E5EC;
    display: flex;
    align-items: center;
    justify-content: center;
    .link {
      padding: 4px;
      width: 100%;
      text-align: center;
      @include transition(0.2s);
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

.body::-webkit-scrollbar {
  width: 4px;
}

.body::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-50;
}

.body::-webkit-scrollbar-thumb {
  // background-color: $green-28a745;
  // outline: 1px solid $green-28a745;
  background-color: $yellow-F0;
  outline: 1px solid $yellow-F0;
}