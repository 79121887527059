@import "../../index.scss";
@import "../../sass/mixins";

.archieveContract {
  .container {
    .head {
    }
    .archForm {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .inputWrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .uploadList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        @media (max-width: $xl-media) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: $md-media) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $sm-media) {
          grid-template-columns: 1fr;
        }
      }
      


      .addBtnBox {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;
        .addBtn {
          background-color: $yellow-F0;
          color: $white;
          padding: 8px 16px;
          width: fit-content;
          @include borderRadius(4px);
          border: 1px solid $yellow-F0;
          font-size: 18px;
          min-width: 150px;
          opacity: 1;
          @include transition(0.2s);
          &:hover {
            opacity: 0.7;
          }
          @media (max-width: $xs-media) {
            width: 100%;
          }
        }
      }
    }
  }
}
