@import "../../../index.scss";
@import "../../../sass/mixins";

.uploadItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  //   border: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  @include borderRadius(4px);
  padding: 8px;
  @media (max-width: $sm-media) {
    width: fit-content;
    // margin: 0px auto;
  }
  .uploadBox {
    .uploadLabel {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .uploadIcon {
        font-size: 24px;
        color: $gray-46;
        @include transition(0.2s);
      }
      .uploadTxt {
        @include transition(0.2s);
        color: $gray-46;
      }
      &:hover {
        .uploadIcon {
          color: $bg-green;
        }
        .uploadTxt {
          color: $bg-green;
        }
      }
    }
  }

  .previewImage {
    min-width: 160px;
    height: 160px;
    height: 220px;
    @include borderRadius(4px);
    overflow: hidden;
    position: relative;
    @media (max-width: $sm-media) {
      width: 250px;
      height: 250px;
    }
    .removeIcon,
    .removeIconGray {
      position: absolute;
      top: 4px;
      right: 4px;
      fill: $bg-red;
      stroke: $white;
      font-size: 24px;
      cursor: pointer;
    }
    .removeIconGray {
      display: none;
    }
    .fullScreen {
      position: absolute;
      top: 4px;
      left: 4px;
      background-color: rgba(255,255,255,0.9);
      border: 1px solid rgba(255,255,255,0.9);
      padding: 4px;
      @include borderRadius(4px);
      @include transition(0.2s);
      cursor: pointer;

      .fullScreenIcon {
        font-size: 20px;
        stroke: $white;
        fill: $secondary;
        @include transition(0.2s);
      }
      &:hover {
        background-color: $yellow-FE;
        border-color: $yellow-FE;
        .fullScreenIcon {
          fill: $white !important;
          color: $white !important;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .imageDetails {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    direction: ltr;
    background-color: rgba(0,0,0,0.08);
    padding: 4px 6px;
    @include borderRadius(4px);
    span {
        font-size: 14px;
        color: $gray-4A;
        text-wrap: nowrap;
        width: 100px;
        overflow: hidden;
        text-align: start;
        &:last-of-type {
            text-align: end;
        }
    }
  }
  .errorMsg {
    background-color: $bg-red;
    padding: 4px 6px;
    @include borderRadius(4px);
    .errorMsgText {
      color: $white;
      font-size: 14px;
    }
  }
  
}
