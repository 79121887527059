@import '../../../index.scss';
@import '../../../sass/mixins';


.allTypes {
    margin-top: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 2px solid $yellow-F4;
    .table {
        
    }
}