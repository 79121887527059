@import "../../../index.scss";
@import "../../../sass/mixins";

.fullScreenImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-A01;

  .container {
    position: relative;
    width: 60vw;
    height: 98vh;
    background-color: $gray-46;
    @include borderRadius(4px);

    .slider {
      padding: 16px 36px 36px 36px;
      .contractImgsSwiper {
      }
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // gap: 35px;
      // width: 100%;
      @media (max-width: $sm-media) {
        // overflow-x: scroll;
      }
      .slide {
        display: flex;
        height: 100%;
        width: 100%;
        height: 80vh;
        .imageDetails {
          height: 100%;
          width: 100%;
          .detailsList {
            margin-bottom: 4px;
            min-height: 100px;
            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 32px;
              .text {
                margin-top: 8px;
                padding-top: 4px;
                padding-bottom: 4px;
                display: flex;
                align-items: center;
                gap: 4px;
                .name {
                  color: $white;
                  font-size: 14px;
                }
                .value {
                  font-size: 14px;
                  color: $info;
                }
              }
              .itemValue {
                font-size: 18px;
                font-weight: 700;
                color: $white;
                span {
                  border-bottom: 2px solid $white;
                  padding-bottom: 2px;
                  margin-bottom: 2px;
                }
                .contract {
                  color: $yellow-FE;
                  border-color: $yellow-FE;
                }
                .testmo {
                  color: $green-51;
                  border-color: $green-51;
                }
                .board {
                  color: $limon-C0;
                  border-color: $limon-C0;
                }
                .banner {
                  color: $red-E4;
                  border-color: $red-E4;
                }
              }
            }
          }
          .image {
            height: 100%;
            width: 100%;
            @include borderRadius(4px);
            display: flex;
            align-items: center;
            justify-content: center;
            // overflow: hidden;
            // border: 2px solid transparent;
            // outline: 2px solid $yellow-FE;
            min-width: 650px;
            min-height: 450px;
            .img {
              width: 100%;
              height: 100%;
              // max-width: 100%;
              // max-height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    .closeIcon {
      font-size: 28px;
      position: absolute;
      top: 10px;
      right: 10px;
      fill: $white;
      color: $bg-red;
      cursor: pointer;
      @include transition(0.2s);
      &:hover {
        color: $bg-red;
        fill: $bg-red;
      }
    }
  }
}
