@import '../../../../index.scss';
@import '../../../../sass/mixins';

.deals {
  padding: 16px;
  width: 100%;
  height: 100%;
  background: url("../../../../../public/images/red-rect-bg.svg");
  .head {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .title {
      font-weight: 500;
      letter-spacing: .05rem;
      font-size: 1.12rem;
      color: $white;
    }
    svg {
      color: $white;
      cursor: pointer;
      // font-size: 18px;
    }
  }
  .content {
    min-width: 170px;
    height: 170px;
    margin: 0px auto;
  }
}

