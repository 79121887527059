@import '../../../index.scss';
@import '../../../sass/mixins';


.dashboard {
  padding: 28px;
  background-color: $bg-ED;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  @media (max-width: $xs-media) {
    padding: 28px 16px;
  }
  .container {
    display: grid;
    grid-template-areas: 
      "box1 box1 box1 box2 box3" 
      "box1 box1 box1 box2 box3" 
      "box1 box1 box1 box4 box5"
      "box6 box7 box8 box9 box9";
    gap: 28px;
    @media (max-width: $xl-media) {
      grid-template-areas: 
      "box1 box1 box1" 
      "box2 box4 box4" 
      "box2 box5 box5" 
      "box3 box6 box7"
      "box3 box8 box9";
    }
    @media (max-width: $lg-media) {
      grid-template-areas: 
      "box1 box1" 
      "box2 box3" 
      "box4 box5"
      "box6 box7"
      "box8 box9"
      ;
    }
    @media (max-width: $sm-media) {
      grid-template-areas: 
      "box1" 
      "box2" 
      "box3" 
      "box4" 
      "box5"
      "box6"
      "box7"
      "box8"
      "box9"
      ;
    }
    .box1 {
      grid-area: box1;
      padding: 16px;
      height: 380px;
      @media (max-width: $xl-media) {
        height: 400px;
      }
    }
    .box2 {
      grid-area: box2;
      min-height: 220px;
      overflow: hidden;
    }
    .box3 {
      grid-area: box3;
      min-height: 220px;
      background-image: linear-gradient(45deg,#ff1635,#ff7c8d);
      background-repeat: repeat-x;
      overflow: hidden;
      
    }
    .box4 {
      grid-area: box4;
      padding: 16px;
      min-height: 100px;
    }
    .box5 {
      grid-area: box5;
      padding: 16px;
      min-height: 100px;
    }
    .box6 {
      grid-area: box6;
      padding: 16px;
      min-height: 100px;
    }
    .box7 {
      grid-area: box7;
      padding: 16px;
      min-height: 100px;
    }
    .box8 {
      grid-area: box8;
      padding: 16px;
      min-height: 100px;
    }
    .box9 {
      grid-area: box9;
      padding: 16px;
      min-height: 100px;
    }
    .box10 {
      grid-area: box10;
      padding: 16px;
      min-height: 100px;
    }
    .box1,
    .box2,
    .box3,
    .box4,
    .box5,
    .box6,
    .box7,
    .box8,
    .box9,
    .box10 {
      background-color: $white;
      @include borderRadius(8px);
      // padding: 16px;
      box-shadow: $shadow-A04;
    }

    .orders,
    .calls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      height: 100%;
      margin: auto 0px;
      .details {
        .title {
          color: $gray-6b;
          font-weight: 400;
          line-height: 1.2;
          margin-bottom: 8px;
          font-size: 14px;
        }
        .value {
          font-size: 20px;
          margin-bottom: 8px;
          font-weight: 600;
          line-height: 1.2;
          color: $gray-46;
        }
      }
      .icon {
        svg {
          font-size: 40px;
          color: $bg-green;
        }
      }
    }

    .calls {
      .icon {
        svg {
          color: $bg-red;
        }
      }
    }
  }
  .supervisorsTable {
    // flex: 0 0 100%;
    margin-top: 32px;
    // overflow: hidden;
  }
}