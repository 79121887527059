@import '../../../index.scss';
@import '../../../sass/mixins';


.header {
  width: 100%;
  height: 60px;
  box-shadow: $shadow-A02;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  @media (max-width: $xs-media) {
    padding-inline-start: 16px;
    gap: 8px;
  }
  .sideBarIcon {
    display: none;
    @media (max-width: $sm-media) {
      display: flex;
    }
    svg {
      cursor: pointer;
      font-size: 32px;
      @include transition(0.2s);
      &:hover {
        color: $blue-light;
      }
    }
  }
  .search {
    position: relative;
    flex: 1 0 30%;
    @media (max-width: $lg-media) {
      flex: 0 0 20%;
    }
    @media (max-width: $sm-media) {
      flex: 0 0 10%;
    }
    .searchBox {
      position: relative;
      min-width: 300px;
      width: 100%;
      border: 1px solid $gray-100;
      @include borderRadius(6px);
      padding: 4px 8px;
      display: flex;
      align-items: center;
      // gap: 8px;
      @include transition(0.2s);
      &:hover {
        border-color: $primary;
      }
      @media (max-width: $md-media) {
        display: none;
      }
      svg {
        color: $blue-light;
        @include transition(0.2s);
        &:hover {
          color: $primary;
        }
      }
      input {
        padding: 0px 8px;
        color: $secondary;
        width: 100%;
        border: none;
        outline: none;
        background-color: $white;
        &:focus {
          background-color: $white;
        }
        &::placeholder {
          color: $blue-light;
          font-size: 14px;
        }
        &::-webkit-search-cancel-button {
          cursor: pointer;
          -webkit-appearance: none;
          background-color: $yellow-F0;
          -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
          background-size: 20px 20px;
          height: 20px;
          width: 20px;
        }
      }
      .searchError {
        display: block;
        position: absolute;
        // top: 100%;
        right: 50%;
        top: 50%;
        @include transform(translate(-50%, -50%));
        font-size: 14px;
        color: $bg-red;
      }
    }
    .searchIcon {
      display: none;
      @media (max-width: $md-media) {
        display: flex;
        cursor: pointer;
        svg {
          font-size: 28px;
          @media (max-width: $xs-media) {
            font-size: 24px;
          }
        }
      }
    }
    .searchMenu {
      position: absolute;
      top: 50px;
      left: -24px;
      right: -24px;
      width: calc(100vw - 16px - 16px);
      background-color: $white;

      // width: 270px;
      z-index: 1000;
      box-shadow: $shadow-A01;
      @include borderRadius(4px);
      padding: 8px;
      @media (max-width: $sm-media) {
        position: fixed;
        top: 64px;
        left: 16px;
        right: 16px;
        width: calc(90vw - 16px - 16px);
      }
      @media (max-width: $xs-media) {
        position: fixed;
        top: 64px;
        left: 16px;
        right: 16px;
        width: calc(100vw - 16px - 16px);
      }
      .searchBox {
        display: flex;
        // min-width: 200px;
        width: 100%;

      }
    }
  }
  .menus {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: $xs-media) {
      gap: 16px;
    }
    .language {
      position: relative;
      // margin: auto 0px;
      .selectedLang {
        cursor: pointer;
        img {
          width: 32px;
          @include borderRadius(4px);
          @media (max-width: $xs-media) {
            width: 24px;
          }
        }
      }
      .langMenu {
        width: 120px;
        z-index: 1000;
        position: absolute;
        top: 44px;
        // left: -16px;
        // right: -16px;
        left: 0;
        right: 0;
        background-color: $white;
        box-shadow: $shadow-A01;
        @include borderRadius(4px);
        // width: 50px;
        padding-top: 8px;
        padding-bottom: 8px;
        li {
          cursor: pointer;
          text-align: center;
          padding: 8px 16px;
          width: 100%;
          font-size: 18px;
          @include transition(0.3s);
          display: flex;
          align-items: center;
          gap: 8px;
          &:first-of-type {
            border-bottom: 1px solid $gray-100;
          }
          &:hover {
            background-color: $blue-light;
            color: $white;
          }
          img {
            width: 28px;
          }
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }

    .notifications {
      position: relative;
      .notificIcon {
        position: relative;
        cursor: pointer;
        svg {
          font-size: 32px;
          @media (max-width: $xs-media) {
            font-size: 24px;
          }
        }
        .notifNum {
          position: absolute;
          top: -4px;
          right: -4px;
          width: 20px;
          height: 20px;
          background-color: $bg-red;
          color: $white;
          @include borderRadius(50%);
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: $shadow-A03;
          font-size: 15px;
          @media (max-width: $xs-media) {
            width: 16px;
            height: 16px;
            font-size: 14px;
          }
        }
      }
    }
    
    .email {
      position: relative;
      .emailIcon {
        cursor: pointer;
        svg {
          font-size: 28px;
          @media (max-width: $xs-media) {
            font-size: 24px;
          }
        }
      }
    }
    
    
    .account {
      position: relative;
  
      .accWrapper {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        .info {
          display: flex;
          flex-direction: column;
          align-items: end;
          @media (max-width: $sm-media) {
            display: none;
          }
          .name {
            font-weight: 600;
            color: #6B6F82;
          }
          .email {
            font-size: 14px;
            color: #6B6F82;
          }
        }
        .avatar {
          width: 36px;
          height: 36px;
          @include borderRadius(50%);
          overflow: hidden;
          border: 2px solid transparent;
          outline: 2px solid $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: none;
          }
          .alias {
            background-color: #C7D2FE;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              font-weight: 600;
              color: #4435A3;
            }
            .firstL {
    
            }
            .seondL {
    
            }
          }
        }
        .logoutBtn {
          border: 2px solid $bg-red;
          background-color: $bg-red;
          width: 36px;
          height: 36px;
          // padding: 2px;
          @include borderRadius(50%);
          display: flex;
          align-items: center;
          justify-content: center;
          @include transition(0.2s);
          svg {
            font-size: 26px;
            color: $white;
          }
          &:hover {
            background-color: $white;
            svg {
              color: $bg-red;
            }
          }
        }
      }
    }
  }
}