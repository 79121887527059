@import '../../../index.scss';
@import '../../../sass/mixins';

.errorPage {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // box-shadow: $shadow-5;
  // position: relative;
  // background-color: $gray-495057;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    @media (max-width: $xs-media) {
      gap: 32px;
    }
    .title {
      font-size: 32px;
      color: $gray-700;
      display: flex;
      align-items: center;
      gap: 6px;
      @media (max-width: $sm-media) {
        font-size: 28px;
      }
      @media (max-width: $xs-media) {
        font-size: 26px;
      }
      span {
        color: $white;
        background-color: $bg-red;
        padding: 4px 8px;
        @include borderRadius(4px);
      }
    }
    .image {
      width: 40%;
      // height: 500px;
      @media (max-width: $xl-media) {
        width: 60%;
      }
      @media (max-width: $lg-media) {
        width: 70%;
      }
      @media (max-width: $md-media) {
        width: 80%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .secTitle {
      font-size: 24px;
      position: relative;
      background-color: $primary;
      color: $white;
      padding: 8px 32px;
      @include borderRadius(24px 0 24px 0);
      @media (max-width: $xs-media) {
        font-size: 20px;
      }
    }
  }
}