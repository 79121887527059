@import '../../../../index.scss';
@import '../../../../sass/mixins';

.table {
  width: 82vw;
  width: 72vw;
  // height: 90vh;
  max-height: 70vh;
  width: 100%;
  // height: 100%;
  background-color: #FFF5;
  backdrop-filter: blur(7px);
  box-shadow: 0 0.4rem 0.8rem #0005;
  @include borderRadius(0.8rem);
  overflow: hidden;
  .head {
    width: 100%;
    height: 10%;
    background-color: #FFF4;
    padding: 0.8rem 1rem;
    text-align: center;
    h1 {
      color: $primary;
      font-size: 20px;
      font-weight: 700;
    }
  }
  .body {
    width: 95%;
    height: calc(89% - 0.8rem - 0.8rem);
    background-color: #FFFB;
    margin: 0.8rem auto;
    @include borderRadius(0.6rem);
    overflow: auto;
    table {
      width: 100%;
      thead {
        tr {
          th {
            padding: 1rem;
            border-collapse: collapse;
            position: sticky;
            top: 0;
            right: 0;
            background-color: #d5d1defe;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #0000000b;
          }
          &:hover {
            background-color: #FFF6;
          }
          td {
            text-align: center;
            padding: 1rem;
            vertical-align: middle;
            @media (max-width: $lg-media) {
              min-width: 12.1rem;
            }
            .image {
              display: flex;
              align-items: center;
              justify-content: center;
              // flex-direction: column;
              // align-items: center;
              img {
                width: 36px;
                height: 36px;
                margin-inline-end: 8px;
                @include borderRadius(50%);
              }
            }
            .status {
              text-align: center;
              padding: 0.4rem 0.1rem;
              @include borderRadius(2rem);
  
              &.active {
                background-color: #86e49d;
                color: #006b21;
              }
              &.notActive {
                background-color: #D893A3;
                color: #B30021;
              }
              &.pending {
                background-color: #ebc474;
              }
              &.shipped {
                background-color: #6fcaea;
              }
            }
          }
        }
      }
    }
  }
  .body::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  .body::-webkit-scrollbar-thumb {
    @include borderRadius(0.5rem);
    background-color: #0004;
    visibility: hidden;
  }
  .body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

