@import '../../../index.scss';
@import '../../../sass/mixins';


.addParent {
    margin-top: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 2px solid $secondary;
    .inpBox {
        width: 50%;
        @media (max-width: $lg-media) {
            width: 70%;
        }
        @media (max-width: $md-media) {
            width: 100%;
        }
        .parentLabel {
            color: $gray-6b;
            font-size: 16px;
            margin-bottom: 4px;
            display: block;
        }
    }
    .addBtn {
        margin-top: 8px;
        @include borderRadius(4px);
        border: 1px solid $secondary;
        background-color: $secondary;
        color: $white;
        padding: 8px 16px;
        font-size: 18px;
        @include transition(0.3s);
        &:hover {
            background-color: $white;
            color: $secondary;
        }
        @media (max-width: $sm-media) {
            width: 100%;
        }
    }
}